import { getIPInfo } from 'api/users'
import { useQuery } from 'react-query'

export function useIpInfo() {
  return useQuery({
    queryKey: ['ip-info'],
    queryFn: async () => {
      const data = await getIPInfo()

      return data
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })
}
