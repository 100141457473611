import { IUser } from '@/types'
import { API_BASEPATH, Err } from '.'

export const userErrors = {
  wrongPassword: 'Old Passwords not ok',
  passwordsDontMatch: 'Passwords do not match',
  emailAlreadyTaken: 'Email already taken',
  emailAlreadyTakenNotGoogle: 'Email already taken (not google)',
  emailAlreadyTakenNotApple: 'Email already taken (not apple)',
  googleAccountExists: 'The user is registered with google',
  appleAccountExists: 'The user is registered with apple',
  invalidIdentifier: 'Invalid identifier or password',
}

type ChangePasswordCredentials = {
  oldPassword: string
  password: string
  passwordConfirmation: string
}

type GetCurrentUserResponse = IUser & {
  error?: Err
}

type GetCurrentSocialUserResponse = {
  user?: IUser
  jwt?: string
  error?: Err
}

type ChangePasswordResponse = {
  message?: string
  error?: Err
}

export type GetIPInfoRes = {
  status?: number
  error?: {
    message?: string
    title?: string
  }
  city?: string
  country?: string
  hostname?: string
  ip?: string
  postal?: string
}

export const getCurrentUser = async () => {
  const response = await fetch(`${API_BASEPATH}/api/users/me`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  const data = await response.json()
  return data as GetCurrentUserResponse
}

export const getCurrentSocialUser = async (
  accessToken: string,
  provider: 'google' | 'apple',
  firstName?: string,
  lastName?: string
) => {
  const endpoint = new URL(`${API_BASEPATH}/api/auth/${provider}/callback`)

  endpoint.searchParams.append('access_token', accessToken)

  if (firstName) endpoint.searchParams.append('firstName', firstName)
  if (lastName) endpoint.searchParams.append('lastName', lastName)

  const response = await fetch(endpoint)
  const data = await response.json()
  return data as GetCurrentSocialUserResponse
}

export const changePassword = async ({
  oldPassword,
  password,
  passwordConfirmation,
}: ChangePasswordCredentials) => {
  const response = await fetch(`${API_BASEPATH}/api/users/changePassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({
      oldPassword,
      password,
      passwordConfirmation,
    }),
  })
  const data = await response.json()
  return data as ChangePasswordResponse
}

export const getIPInfo = async (ip?: string) => {
  const countryIp = localStorage.getItem('countryIp')

  if (countryIp) return { country: countryIp }

  const response = await fetch(
    `https://ipinfo.io/json${ip || ''}?token=999e00db2815d7`
  )
  const data = await response.json()

  localStorage.setItem('countryIp', data?.country)
  return data as GetIPInfoRes
}
