import { AccessFairartModal } from 'components/common/AccessFairartModal'
import { useFeatureFlags } from 'hooks'
import { useRouter } from 'next/router'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useOverlay } from '../hooks/'

export const RELEASE_DATE = new Date(Date.UTC(2022, 11, 6, 13))

type State = {
  hasAccess: boolean | undefined
  setHasAccess: Dispatch<SetStateAction<boolean | undefined>>
  verifyPassword: (password: string) => boolean
}

type Props = {
  children: ReactNode
}

const ACCESS_PASS = 'FairArtTestingEnv2024!'
const ACCESS_PASS_LS_KEY = 'access_pass'

export const AccessContext = createContext<State>({} as State)

export const AccessProvider = ({ children }: Props) => {
  const [hasAccess, setHasAccess] = useState<boolean | undefined>(undefined)
  const { featureFlags, isFetched } = useFeatureFlags()

  const router = useRouter()
  const { setConfig, setIsOpen } = useOverlay()

  const intercept = () => {
    if (hasAccess) return

    router.events.emit('routeChangeError')
    throw 'You don`t have access to use the website'
  }

  useEffect(() => {
    const determineAccess = () => {
      if (!isFetched) {
        return
      }

      if (
        typeof featureFlags === 'undefined' ||
        !featureFlags['access-modal']
      ) {
        setHasAccess(true)
        return
      }

      const accessPass = localStorage.getItem(ACCESS_PASS_LS_KEY)
      setHasAccess(accessPass === ACCESS_PASS)
    }

    determineAccess()
  }, [featureFlags, isFetched])

  useEffect(() => {
    if (hasAccess !== false) return

    router.push('/').then(() => {
      setConfig({
        body: <AccessFairartModal />,
        closeable: false,
        className: 'px-6 items-center overflow-y-auto backdrop-blur',
      })
      setIsOpen(true)

      router.events.on('routeChangeStart', intercept)
    })

    return () => {
      router.events.off('routeChangeStart', intercept)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAccess])

  const verifyPassword = useCallback((password: string) => {
    if (password === ACCESS_PASS) {
      localStorage.setItem(ACCESS_PASS_LS_KEY, password)
      setHasAccess(true)
      setIsOpen(false)
      return true
    }

    return false
  }, [])

  return (
    <AccessContext.Provider value={{ hasAccess, setHasAccess, verifyPassword }}>
      {children}
    </AccessContext.Provider>
  )
}
