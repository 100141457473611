import { IRelease, IReleaseNotification } from '@/types'
import { API_BASEPATH, Err } from 'api'
import dayjs from 'dayjs'
import qs from 'qs'
import { StrapiPagination } from 'types'

export const releaseCardPopulation = {
  fields: ['name', 'extraArtists', 'raffle'],
  populate: {
    releaseDate: {
      fields: '*',
    },
    artworkImages: {
      fields: ['name', 'url', 'formats'],
    },
    artists: {
      fields: ['name'],
    },
  },
}

function getCurrentTimestamp() {
  const timestamp = dayjs().tz('GMT').unix()

  return timestamp * 1000
}

function buildPastReleasesFilters() {
  const timestamp = getCurrentTimestamp()

  return {
    releaseDate: {
      id: { $null: false },
      releaseStartTimestamp: { $lt: timestamp },
    },
  }
}

function buildFutureReleasesFilters() {
  const timestamp = getCurrentTimestamp()

  return {
    releaseDate: {
      id: { $null: false },
      releaseStartTimestamp: { $gte: timestamp },
    },
  }
}

export type Release = {
  id: number
} & IRelease['attributes']

type GetReleasesResponse = {
  data: IRelease[]
  meta: {
    pagination: StrapiPagination
  }
  error?: Err
}

type GetReleaseResponse = {
  data?: IRelease
  error?: Err
}

type GetReleaseNotificationsRes = {
  data?: IReleaseNotification[]
  error?: Err
  meta?: {
    pagination: StrapiPagination
  }
}

export const getReleases = async (query?: string) => {
  const response = await fetch(`${API_BASEPATH}/api/releases?${query}`)
  const data = await response.json()
  return data as GetReleasesResponse
}

export const getRelease = async (id: number, query?: string) => {
  const response = await fetch(`${API_BASEPATH}/api/releases/${id}?${query}`)
  const data = await response.json()
  return data as GetReleaseResponse
}

export const submitRelease = async (payload: {
  releaseName: string
  artistName: string
  url: string
  files: File[]
}) => {
  const formData = new FormData()

  Object.entries(payload).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => formData.append(key, item))
      return
    }

    formData.append(key, value)
  })

  const response = await fetch(`${API_BASEPATH}/api/releases/submit`, {
    method: 'POST',
    body: formData,
  })
  try {
    const data = await response.clone().json()
    return data as { error?: Err }
  } catch (err) {
    return await response.text()
  }
}

export const saveRelease = async (id: number) => {
  const response = await fetch(
    `${API_BASEPATH}/api/release-notifications/save`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ releaseId: id }),
    }
  )
  const data = await response.json()
  return data as { success?: boolean; error?: Err }
}

export const unsaveRelease = async (id: number) => {
  const response = await fetch(
    `${API_BASEPATH}/api/release-notifications/delete`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ releaseId: id }),
    }
  )
  const data = await response.json()
  return data as { success?: boolean; error?: Err }
}

export const getReleasesNotifications = async (query?: string) => {
  const response = await fetch(
    `${API_BASEPATH}/api/release-notifications?${query}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  )
  const data = await response.json()
  return data as GetReleaseNotificationsRes
}

type Config = {
  pagination?: Partial<Omit<StrapiPagination, 'total' | 'pageCount'>>
  filters?: Record<string, unknown>
}

export const getPastReleases = async (config?: Config) => {
  const query = qs.stringify(
    {
      ...releaseCardPopulation,
      filters: { ...config?.filters, ...buildPastReleasesFilters() },
      sort: ['releaseDate.releaseStartTimestamp:desc'],
      pagination: config?.pagination,
    },
    { encodeValuesOnly: true }
  )

  return getReleases(query)
}

export const getFutureReleases = async (config?: Config) => {
  const query = qs.stringify(
    {
      ...releaseCardPopulation,
      filters: { ...config?.filters, ...buildFutureReleasesFilters() },
      sort: ['releaseDate.releaseStartTimestamp:asc'],
      pagination: config?.pagination,
    },
    { encodeValuesOnly: true }
  )

  return getReleases(query)
}

/**
 * There are the releases which don't have a defined release time yet.
 */
export const getComingReleases = async (config?: Config) => {
  const query = qs.stringify(
    {
      ...releaseCardPopulation,
      filters: {
        releaseDate: {
          id: { $null: true },
        },
      },
      pagination: config?.pagination,
      sort: {
        artists: {
          name: 'asc',
        },
        extraArtists: 'asc',
      },
    },
    { encodeValuesOnly: true }
  )

  return getReleases(query)
}

export const getPastSavedReleases = async (
  userId: number,
  config?: Config
): Promise<{ data: IRelease[] }> => {
  const query = qs.stringify(
    {
      populate: {
        release: {
          ...releaseCardPopulation,
        },
      },
      filters: {
        user: {
          id: userId,
        },
        release: buildPastReleasesFilters(),
      },
      sort: ['release.releaseDate.releaseStartTimestamp:desc'],
      pagination: config?.pagination,
    },
    { encodeValuesOnly: true }
  )

  const { data: notifications } = await getReleasesNotifications(query)

  if (!notifications?.length) return { data: [] }

  return {
    data: notifications
      .map(notif => notif.attributes.release?.data)
      .filter(Boolean) as IRelease[],
  }
}

export const getFutureSavedReleases = async (
  userId: number,
  config?: Config
): Promise<{ data: IRelease[] }> => {
  const query = qs.stringify(
    {
      populate: {
        release: {
          ...releaseCardPopulation,
        },
      },
      filters: {
        user: {
          id: userId,
        },
        release: buildFutureReleasesFilters(),
      },
      sort: ['release.releaseDate.releaseStartTimestamp:asc'],
      pagination: config?.pagination,
    },
    { encodeValuesOnly: true }
  )

  const { data: notifications } = await getReleasesNotifications(query)

  if (!notifications?.length) return { data: [] }

  return {
    data: notifications
      .map(notif => notif.attributes.release?.data)
      .filter(Boolean) as IRelease[],
  }
}
