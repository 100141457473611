import { Dayjs } from 'dayjs'

const Sunday = 0
const Saturday = 6

const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'always' })

export const addBusinessDays = (originalDate: Dayjs, numDaysToAdd: number) => {
  let daysRemaining = numDaysToAdd

  const newDate = originalDate.clone()

  if (numDaysToAdd === 0) return newDate

  if (numDaysToAdd > 0) {
    while (daysRemaining > 0) {
      newDate.add(1, 'days')
      if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
        daysRemaining--
      }
    }
  } else {
    while (daysRemaining < 0) {
      newDate.subtract(1, 'days')
      if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
        daysRemaining++
      }
    }
  }

  return newDate
}

export const getBusinessDaysDiff = (start: Dayjs, end: Dayjs) => {
  let numWorkDays = 0
  let currentDate = start.clone()
  if (currentDate.isBefore(end)) {
    while (currentDate <= end) {
      // Skips Sunday and Saturday
      if (currentDate.day() !== 0 && currentDate.day() !== 6) {
        numWorkDays++
      }
      currentDate = currentDate.add(1, 'day')
    }
  } else {
    while (currentDate > end) {
      if (currentDate.day() !== 0 && currentDate.day() !== 6) {
        numWorkDays++
      }
      currentDate = currentDate.subtract(1, 'day')
    }
  }
  return numWorkDays
}

export const formatLocalDate = (date: Dayjs, separator = '-') => {
  if (!date?.isValid?.()) {
    return 'Until accepted'
  }

  return date.format(`DD${separator}MM${separator}YYYY`)
}

export function formatRelativeTime(date: Date) {
  const now = new Date()
  const elapsed = (now.getTime() - date.getTime()) / 1000 // Difference in seconds

  // Define time units in seconds
  const units: { label: Intl.RelativeTimeFormatUnit; seconds: number }[] = [
    { label: 'year', seconds: 31536000 }, // 60 * 60 * 24 * 365
    { label: 'month', seconds: 2592000 }, // 60 * 60 * 24 * 30
    { label: 'week', seconds: 604800 }, // 60 * 60 * 24 * 7
    { label: 'day', seconds: 86400 }, // 60 * 60 * 24
    { label: 'hour', seconds: 3600 }, // 60 * 60
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 },
  ]

  // Find the most appropriate unit
  for (const unit of units) {
    const diff = Math.floor(elapsed / unit.seconds)
    if (Math.abs(diff) >= 1) {
      return rtf.format(-diff, unit.label)
    }
  }

  return rtf.format(0, 'second') // If less than 1 second has passed
}

export function formatShortRelativeTime(date: Date) {
  const relativeTime = formatRelativeTime(date)
  const [value, unit, ago] = relativeTime.split(' ')

  return `${value}${unit.slice(0, 1)} ${ago}`
}
