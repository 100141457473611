import { IAddress, ICard, IProfile, IUser } from '@/types'
import { API_BASEPATH, Err } from '.'
import { sellerTypes } from 'constants/'

export type SellerType = keyof typeof sellerTypes

export type UserProfile = { id: number } & IProfile['attributes'] // This might need to be updated if the backend changes

type GetUserProfileResponse = {
  error?: Err
  message?: string
  profile?: UserProfile
}

type UpdateUserProfileResponse = {
  error?: Err
  message?: string
  profile?: UserProfile
}

type UpdateUserProfileCurrencyResponse = {
  error?: Err
  data?: UserProfile
}

type HasUserLiveListingsOrOffers = {
  error?: Err
  data?: { hasListingOffersLive: boolean }
}

type SetupExpressPaymentResponse = {
  error?: Err
  message?: string
  redirectUrl?: string
}

export type Profile = {
  firstName?: string
  lastName?: string
  mobilePhone?: string
  recipientName?: string
  communication?: boolean
  bidNewLowestAsk?: boolean
  bidNewHighestBid?: boolean
  bidNewSale?: boolean
  askNewLowestAsk?: boolean
  askNewHighestBid?: boolean
  askNewSale?: boolean
  followArtistNewLowestAsk?: boolean
  followArtistNewHighestBid?: boolean
  followArtistNewSale?: boolean
  saveArtworkNewLowestAsk?: boolean
  saveArtworkNewHighestBid?: boolean
  saveArtworkNewSale?: boolean
  user?: { data?: IUser }
  connectAccountId?: string
  customerAccountId?: string
  last4?: string
  shippingAddress?: Partial<IAddress>
  senderAddress?: Partial<IAddress>
  billingAddress?: Partial<IAddress>
  buyingCard?: Partial<ICard>
  sellerType?: SellerType
}

export const getUserProfile = async (userId: number, query?: string) => {
  const response = await fetch(
    `${API_BASEPATH}/api/profiles/${userId}?${query}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  )
  const data = await response.json()
  return data as GetUserProfileResponse
}

export const updateUserProfile = async (
  userId: number,
  newProfile: Profile,
  query?: string
) => {
  const response = await fetch(
    `${API_BASEPATH}/api/profiles/${userId}?${query}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(newProfile),
    }
  )
  const data = await response.json()
  return data as UpdateUserProfileResponse
}

export const updateProfileCurrency = async (
  userId: number,
  currency: string
) => {
  const response = await fetch(
    `${API_BASEPATH}/api/profiles/update/currency/${userId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ currency }),
    }
  )
  const data = await response.json()
  return data as UpdateUserProfileCurrencyResponse
}

export const hasUserLiveListingsOrOffers = async () => {
  const response = await fetch(
    `${API_BASEPATH}/api/profiles/has-listings-offers-live`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  )
  const data = await response.json()
  return data as HasUserLiveListingsOrOffers
}

export const updateUserProfilePreferences = async (
  newProfile: Profile,
  query?: string
) => {
  const response = await fetch(
    `${API_BASEPATH}/api/profiles/update/user/preferences?${query}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(newProfile),
    }
  )
  const data = await response.json()
  return data as UpdateUserProfileResponse
}

export const getClientSecret = async () => {
  const response = await fetch(`${API_BASEPATH}/api/profiles/payment`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  const data = await response.json()
  return data as { clientSecret: string }
}

export const setupExpressPayment = async (
  refreshUrl: string,
  returnUrl: string
) => {
  const response = await fetch(`${API_BASEPATH}/api/profiles/express`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({ refreshUrl, returnUrl }),
  })
  const data = await response.json()
  return data as SetupExpressPaymentResponse
}

export const updateReleaseNotifications = async (payload: {
  timeZone: string
  notification1: number
  notification2: number
}) => {
  const response = await fetch(
    `${API_BASEPATH}/api/profiles/update/user/release`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(payload),
    }
  )
  const data = await response.json()
  return data as { error?: Err; success?: boolean }
}

export const saveLastActive = async () => {
  const response = await fetch(`${API_BASEPATH}/api/profiles/leave`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  const data = await response.json()
  return data as { error?: Err; success?: boolean }
}
