import { API_BASEPATH } from 'api'
import { useQuery } from 'react-query'

const ExchangeRateNames = {
  eurToEur: 'eurToEur',
  eurToGbp: 'eurToGbp',
  eurToUsd: 'eurToUsd',
  gbpToEur: 'gbpToEur',
  gbpToGbp: 'gbpToGbp',
  gbpToUsd: 'gbpToUsd',
  usdToEur: 'usdToEur',
  usdToGbp: 'usdToGbp',
  usdToUsd: 'usdToUsd',
} as const

export type ExchangeRateName =
  (typeof ExchangeRateNames)[keyof typeof ExchangeRateNames]

export interface ExchangeRate {
  id: number
  name: ExchangeRateName
  value: number
}

export type ExchangeRates = Record<ExchangeRateName, ExchangeRate>

export type MappedExchangeRates = Record<ExchangeRateName, number>

interface GetCurrentExchangeRateResponse {
  data?: ExchangeRates
  success?: true
}

export async function getCurrentExchangeRate() {
  const res = await fetch(`${API_BASEPATH}/api/exchanges/exchange-rates/get`)
  const data = await res.json()

  return data as GetCurrentExchangeRateResponse
}

export function useCurrentExchangeRate() {
  return useQuery({
    queryKey: ['exchange-rate'],
    queryFn: async () => {
      const { data: exchangeRates } = await getCurrentExchangeRate()

      if (!exchangeRates) return null

      return Object.values(exchangeRates).reduce(
        (prev, exchangeRate) => ({
          ...prev,
          [exchangeRate.name]: exchangeRate.value,
        }),
        {}
      ) as MappedExchangeRates
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    staleTime: Infinity,
  })
}
