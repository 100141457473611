import classNames from 'classnames'
import { Icon } from 'components/common/Icon'
import { useOverlay } from 'contexts'
import React, { ReactNode } from 'react'
import { PropsWithClassName } from 'types'

type Props = {
  header?: ReactNode
  headerClassName?: string
  body: ReactNode
  bodyClassName?: string
  footer?: ReactNode
  footerClassName?: string
  hideCloseButton?: boolean
} & PropsWithClassName

export const Modal = ({
  className,
  header,
  headerClassName,
  body,
  bodyClassName,
  footer,
  footerClassName,
  hideCloseButton,
}: Props) => {
  const { setIsOpen } = useOverlay()

  return (
    <div className={`rounded bg-cool-50 ${className}`}>
      {header && (
        <header
          className={classNames(
            'flex w-full items-center justify-between border-b border-cool-300 p-6 pb-4',
            headerClassName
          )}
        >
          {header}

          {!hideCloseButton && (
            <button type='button' onClick={() => setIsOpen(false)}>
              <Icon name='close' className='text-cool-600' size={24} />
            </button>
          )}
        </header>
      )}

      <main className={classNames('w-full p-6 pt-4', bodyClassName)}>
        {body}
      </main>

      {footer && (
        <footer
          className={classNames(
            'w-full border-t border-cool-300 p-6 pt-4',
            footerClassName
          )}
        >
          {footer}
        </footer>
      )}
    </div>
  )
}
