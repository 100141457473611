import { useCurrentExchangeRate } from 'api/exchange-rate'
import { Currencies, Currency } from 'constants/currencies'
import { useAuth } from 'contexts/hooks'
import { useUserProfile } from 'hooks'
import { useIpInfo } from 'hooks/useIpInfo'
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react'
import {
  pickExchangeRate as _pickExchangeRate,
  ConvertPayload,
  convertValue,
  determineUserCurrency,
  fallbackCurrencies,
} from 'utils/currencies'
import { formatCurrency } from 'utils/strings'

interface FormatConfig extends ConvertPayload, Intl.NumberFormatOptions {}

interface State {
  userCurrency: Currency
  isLoadingUserCurrency: boolean
  pickExchangeRate: (from: Currency, to: Currency) => number
  getConvertedValue: (
    value: number | undefined,
    payload?: ConvertPayload
  ) => number | undefined
  formatCurrency: (
    value: number | undefined,
    formatConfig?: FormatConfig
  ) => string
  setSelectedCurrency: any
  selectedCurrency: Currency
}

export const CurrenciesContext = createContext<State>({} as State)

export function CurrenciesProvider({ children }: PropsWithChildren) {
  const { user, isLoading: isLoadingUser } = useAuth()
  const [selectedCurrency, setSelectedCurrency] = useState(Currencies.GBP)
  const { data: userProfile, isLoading: isLoadingUserProfile } = useUserProfile(
    {
      options: { enabled: Boolean(user) },
    }
  )
  const { data: ipInfo, isLoading: isLoadingIpInfo } = useIpInfo()
  const { data: exchangeRate } = useCurrentExchangeRate()

  const { userCurrency, isLoadingUserCurrency } = useMemo(() => {
    return {
      userCurrency: determineUserCurrency(
        userProfile,
        ipInfo,
        selectedCurrency
      ),
      isLoadingUserCurrency:
        isLoadingIpInfo || isLoadingUser || isLoadingUserProfile,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingIpInfo,
    isLoadingUser,
    isLoadingUserProfile,
    userProfile?.shippingAddress?.country,
    userProfile?.countryIp,
    userProfile?.currency,
    ipInfo?.country,
    selectedCurrency,
  ])

  const pickExchangeRate = useCallback(
    (from: Currency, to: Currency) => {
      return _pickExchangeRate({ from, to, exchange: exchangeRate })
    },
    [exchangeRate]
  )

  const getConvertedValue = useCallback(
    (value: number | undefined, payload: ConvertPayload = {}) => {
      return convertValue(value, {
        exchange: exchangeRate,
        userProfile,
        selectedCurrency,
        ipInfo,
        ...payload,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      exchangeRate,
      userProfile?.shippingAddress?.country,
      ipInfo?.country,
      userProfile?.countryIp,
      userProfile?.currency,
    ]
  )

  const format = useCallback(
    (value: number | undefined, formatConfig: FormatConfig = {}) => {
      const { from, to } = fallbackCurrencies({
        from: formatConfig.from,
        to: formatConfig.to,
        userProfile,
        ipInfo,
        selectedCurrency,
      })

      const convertedValue = getConvertedValue(value, { from, to })

      let currency = formatConfig.currency || to
      // if the values are equal it means that the currencies are the same or there was an error when converting the value
      // if there was an error, we should display the `from` currency, to avoid displaying wrong value for the wrong currency
      if (!formatConfig.currency && value === convertedValue) {
        currency = from
      }

      return formatCurrency(convertedValue, { ...formatConfig, currency })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getConvertedValue]
  )

  return (
    <CurrenciesContext.Provider
      value={{
        isLoadingUserCurrency,
        userCurrency,
        formatCurrency: format,
        getConvertedValue,
        pickExchangeRate,
        setSelectedCurrency,
        selectedCurrency,
      }}
    >
      {children}
    </CurrenciesContext.Provider>
  )
}
