import { Button } from 'components/common/Button'
import { Input } from 'components/common/Input'
import { Modal } from 'components/common/Modal'
import { Separator } from 'components/common/Separator'
import { useAccess } from 'contexts'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import { FormEvent, useState } from 'react'

const inputName = 'access_password'

export function AccessFairartModal() {
  const { t } = useTranslation('common')
  const { verifyPassword } = useAccess()
  const [error, setError] = useState(null)

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const password = formData.get(inputName)

    if (!password || typeof password !== 'string') {
      return
    }

    if (!verifyPassword(password)) {
      setError(t('incorrect_password'))
    }
  }

  return (
    <Modal
      hideCloseButton
      className='!bg-transparent min-w-[28.125rem]'
      headerClassName='bg-primary-default rounded-t-lg'
      header={
        <div className='pb-2 px-2 flex flex-col items-center space-y-2 text-center'>
          <Image
            src='/files/logo-fairart-light.png'
            alt='Fairart Logo'
            width={118}
            height={29}
            quality={100}
          />

          <p className='text-sm whitespace-pre-line text-cool-200'>
            {t('you_are_on_fairart_test_env')}
          </p>
        </div>
      }
      bodyClassName='bg-cool-50'
      body={
        <div className='px-2 pt-2 flex flex-col items-center text-center'>
          <h2 className='font-medium text-base font-body tracking-normal'>
            {t('access_fairart')}
          </h2>
          <h3 className='text-sm text-cool-600 mt-1 font-body tracking-normal font-normal'>
            {t('you_will_be_redirected_to_fairart')}
          </h3>
          <Button
            type='link'
            href='https://www.fairart.io'
            className='mt-3'
            style='solid-primary'
          >
            {t('go_to_fairart')}
          </Button>

          <Separator className='my-6' color='cool-300' />

          <h2 className='font-medium text-base font-body tracking-normal'>
            {t('team_access')}
          </h2>

          <form
            className='flex space-x-3 w-full mt-3 text-start'
            onSubmit={onSubmit}
          >
            <Input
              type='password'
              pattern={null}
              placeholder={t('password')}
              autoComplete='off'
              className='w-full'
              name={inputName}
              required
              error={Boolean(error)}
              caption={error}
            />

            <Button type='submit'>{t('access')}</Button>
          </form>
        </div>
      }
    />
  )
}
