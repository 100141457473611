import { CurrencySymbol } from 'components/common/CurrencySymbol'
import { useCurrencies } from 'contexts/hooks/useCurrencies'
import { Children, HTMLProps, useMemo } from 'react'
import { ConvertPayload } from 'utils/currencies'
type SpanProps = Omit<HTMLProps<HTMLSpanElement>, 'classID'>

type Props = {
  formattingOptions?: Intl.NumberFormatOptions & ConvertPayload
  withCurrencySymbol?: boolean
} & SpanProps

/**
 * Accepts a single string/number as children, parses it and displays a formatted string as a monetary value.
 *
 * Before displaying, converts the value from a specified currency (`formattingOptions.from`/default GBP) to a specified currency (`formattingOptions.to`/default USER CURRENCY).
 */
export const Price = ({ children, formattingOptions, ...props }: Props) => {
  const { formatCurrency } = useCurrencies()

  const internalProps = { ...props, translate: 'no' } as const
  const childrenArray = Children.toArray(children)

  const Fbk = useMemo(
    () => (
      <span {...internalProps}>
        <CurrencySymbol />
        --
      </span>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  if (!childrenArray.length) return Fbk

  if (childrenArray.length > 1) {
    console.error(
      'Cannot pass multiple children to Price component, passed children:',
      childrenArray
    )
    return Fbk
  }

  if (typeof children !== 'string' && typeof children !== 'number') {
    console.error(
      'Price component children must be of string/number type, passed type: ',
      typeof children
    )
    return Fbk
  }

  const numValue = Number(children)

  if (!numValue || isNaN(numValue)) {
    if (isNaN(numValue)) {
      console.error(
        'Children passed to price component are invalid numbers, passed children',
        childrenArray
      )
    }
    return Fbk
  }

  return (
    <span {...internalProps}>
      {formatCurrency(numValue, formattingOptions)}
    </span>
  )
}
