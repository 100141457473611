export * from './diacritics'
export * from './expiry'
export * from './routes'
export * from './schema.org'
export * from './styles'

export const minimumValues = {
  minimumAsk: 20, // GBP
  minimumBid: 20, // GBP
  insuranceRecommendation: 1000, // GBP,
  newLead: [
    // minimum amount to reduce/increase prices when becoming/updating Lowest Listing / Highest Offer.
    { range: [0, 99], min: 1 }, // GBP
    { range: [100, 999], min: 5 }, // GBP
    { range: [1000, 4999], min: 10 }, // GBP
    { range: [5000, Infinity], min: 20 }, // GBP
  ],
  getNewLeadMinimumDifference(currentLeadValue: number) {
    const item = this.newLead.find(
      item =>
        item.range[0] <= currentLeadValue && item.range[1] >= currentLeadValue
    )

    if (!item) return 0

    return item.min
  },
} as const

export const ASK_AUTH_TRESHOLD = 30000

export const MAX_PRICE = 999999

export const emails = {
  collecting: 'collecting@fairart.io',
  investment: 'investment@fairart.io',
  support: 'support@fairart.io',
} as const

export const countriesWithState = ['AU', 'IT', 'US', 'CA']

export const coutriesAllowedSelling = ['GB', 'JE', 'GG']

export const sellerTypes = {
  PRIVATE_SELLER: 'PRIVATE_SELLER',
  ART_MARKET_PROFESSIONAL: 'ART_MARKET_PROFESSIONAL',
} as const
